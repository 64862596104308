var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.busqueda)?_c('v-text-field',{staticStyle:{"width":"500px"},attrs:{"append-icon":"fa-search","label":"Búsqueda","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_c('v-data-table',{staticClass:"col-12",attrs:{"headers":_vm.headers,"items":_vm.UpdateItems,"items-per-page":5,"search":_vm.search},scopedSlots:_vm._u([{key:"item.largoName",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.listadoLargo,"item-text":"descripcion","item-value":"largoId"},on:{"change":function($event){return _vm.updateVolumen(item)}},model:{value:(item.largoId),callback:function ($$v) {_vm.$set(item, "largoId", $$v)},expression:"item.largoId"}})]}},{key:"item.espesorName",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.listadoEspesor,"item-text":"descripcion","item-value":"espesorId"},on:{"change":function($event){return _vm.updateVolumen(item)}},model:{value:(item.espesorId),callback:function ($$v) {_vm.$set(item, "espesorId", $$v)},expression:"item.espesorId"}})]}},{key:"item.numeroPlantilla",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{on:{"change":function($event){return _vm.updateVolumen(item)}},model:{value:(item.numeroPlantilla),callback:function ($$v) {_vm.$set(item, "numeroPlantilla", $$v)},expression:"item.numeroPlantilla"}})]}},{key:"item.motivo",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{model:{value:(item.motivo),callback:function ($$v) {_vm.$set(item, "motivo", $$v)},expression:"item.motivo"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('tooltipIcono',{attrs:{"color":"blue","mensaje":"Eliminar Pallet","icono":"fas fa-trash"},on:{"click":function($event){return _vm.$emit('eliminar',item,'ELIMINAR')}}}),_c('tooltipIcono',{attrs:{"color":"error","mensaje":"Editar Pallet","icono":"far fa-edit"},on:{"click":function($event){return _vm.$emit('eliminar',item,'MODIFICAR')}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }