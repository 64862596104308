












































































import {Vue,Component,Prop} from 'vue-property-decorator';
import {State,Action} from 'vuex-class';
import {Largo} from '@/entidades/Maestro/Ktb/Largo'
import { Espesor } from '@/entidades/Maestro/Ktb/Espesor';
import { DespachoKTBVerdeDetalle } from '@/entidades/MaderaVerde/KtbDespacho';
@Component({
    name : 'EditDeletePallet'
})
export default class EditDeletePallet extends Vue
{
     @Prop({type:Array,required:true})
    headers!:Array<any>;

    @Prop({type:Array,required:true})
    items!:Array<any>;

    @Prop({type:Boolean,required:false,default:true})
    busqueda!:boolean;

    @State('largo',{namespace: 'maestroKtb'}) listadoLargo!:Array<Largo>;
    @State('espesor',{namespace: 'maestroKtb'}) listadoEspesor!:Array<Espesor>;
    //variables
    search:string="";


    updateVolumen(item:DespachoKTBVerdeDetalle)
    {
        var largo   = this.listadoLargo.find(c=>c.largoId == item.largoId);
        var espesor = this.listadoEspesor.find(c=>c.espesorId == item.espesorId);

        var calculo:number = Number(((Number(largo?.pie) * item.anchoId * Number(espesor?.pulgada) * Number(item.numeroPlantilla) * Number(espesor?.factorHueco)) /12).toFixed(2));
        item.volumen = calculo;
    }

    get UpdateItems()
    {
        if(this.items.length > 0)
        {
            return this.items;
        }
        else
        {
            return [];
        }
        
    }
}
